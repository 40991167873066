export const ORIOLE_SEARCH = '@@oriole/SEARCH'
export const ORIOLE_FETCH = '@@oriole/FETCH'
export const ORIOLE_FETCH_BEGIN = '@@oriole/FETCH_BEGIN'
export const ORIOLE_FETCH_SUCCESS = '@@oriole/FETCH_SUCCESS'
export const ORIOLE_FETCH_ERROR = '@@oriole/FETCH_ERROR'
export const ORIOLE_FETCH_RECORD = '@@oriole/FETCH_RECORD'
export const ORIOLE_FETCH_RECORD_BEGIN = '@@oriole/FETCH_RECORD_BEGIN'
export const ORIOLE_FETCH_RECORD_SUCCESS = '@@oriole/FETCH_RECORD_SUCCESS'
export const ORIOLE_TAGS = '@@oriole/TAGS'
export const ORIOLE_TAG_DETAIL = '@@oriole/TAG_DETAIL'
